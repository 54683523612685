import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import peIcon from '../../assets/icons/prevent-escalation.svg';
import tvrIcon from '../../assets/icons/ticket-volume-reduction.svg';
import irtIcon from '../../assets/icons/improve-resolution-time.svg';
import icsatIcon from '../../assets/icons/improve-csat.svg';
import { sendEventToGa } from '../../utils';
import carrerIcon from '../../assets/branding/Artboard 1.png';
import aboutIcon from '../../assets/icons/About.png';
import newsIcon from '../../assets/icons/News.png';
import casestudyIcon from '../../assets/icons/casestudy.png';
import videosIcon from '../../assets/icons/video Icon1100.png'
import './navbar.css';
import AppDropDown from '../AppDropDown';
import QuickLinkAboveNav from '../quicklink-above-nav';

type Navbar = {
    white?: boolean;
    transparent?: boolean;
    button?: 'cyan' | 'light-blue' | 'yellow';
};

const Navbar: React.FC<Navbar> = ({ white, transparent, button }) => {
    const mobileNavRef = useRef<null | HTMLInputElement>(null);
    const navLinkClass = white ? 'nav-link white' : 'nav-link';

    let pageBody: null | HTMLBodyElement;

    const handleMobileMenuChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (pageBody) {
            if (e.currentTarget.checked) {
                pageBody.style.overflow = 'hidden';
                return;
            }
            pageBody.style.overflow = 'auto';
        }
    };

    useEffect(() => {
        if (typeof document !== 'undefined') {
            pageBody = document.getElementsByTagName('body')[0];
            pageBody.style.overflow = 'auto';
        }
    }, []);

    const handleMobileMenuClick = () => {
        if (mobileNavRef.current) {
            mobileNavRef.current.checked = false;
        }
        if (pageBody) {
            pageBody.style.overflow = 'auto';
        }
    };
    const useCasesDropDownContent = React.useMemo(
        () => [
            {
                title: 'Prevent Escalation',
                description: 'GPT powered answers for immediate tier 1 resolution, reducing escalation up to 35%',
                icon: peIcon,
                link: '/prevent-escalations/'
            },
            {
                title: 'Ticket Volume Reduction',
                description: 'GPT powered answers to automate and deflect support cases, leading to 30% more agent productivity',
                icon: tvrIcon,
                link: '/ticket-volume-reduction/'
            },
            {
                title: 'Improved Resolution Time',
                description: 'GPT powered answers to improve first contact resolutions and average handle time by 40%',
                icon: irtIcon,
                link: '/improved-resolution-time/'
            },
            {
                title: 'Improved CSAT',
                description: 'GPT powered answers creating smarter agents for better customer experience',
                icon: icsatIcon,
                link: '/improved-csat/'
            }
        ],
        []
    );
    const aboutdropDownContent = React.useMemo(
        () => [
            {
                title: 'Careers',
                description: "GPT poweredddd  ",
                icon: carrerIcon,
                class: true,
                link: '/careers/'
            }
        ],
        []
    );
    const resourcesdropDownContent = React.useMemo(
        () => [
            {
                title: 'Blogs',
                description: "GPT poweredddd ",
                icon: aboutIcon,
                class: true,
                link: '/blog/'
            },
            {
                title: 'News',
                description: "GPT poweredddd  ",
                icon: newsIcon,
                class: true,
                link: '/news/'
            },
            {
                title: 'Case Studies',
                description: "GPT poweredddd  ",
                icon: casestudyIcon,
                class: true,
                link: '/case-studies/'
            },
            {
                title: 'Videos',
                description: "GPT poweredddd  ",
                icon: videosIcon,
                class: true,
                link: '/videos/'
            }
        ],
        []
    );
    return (
        <nav className={transparent ? 'transparent' : ''} style={{ backgroundColor: '#fff' }}>
            <QuickLinkAboveNav />
            <div className="container desktop-navbar-wrapper">
                <a href="/" className="navbar-logo">
                    {white ? (
                        <StaticImage
                            src="../../assets/branding/navLogo.png"

                            alt="Aptedge Logo"
                            placeholder="none"
                            loading="eager"
                        />
                    ) : (
                        <StaticImage
                            src="../../assets/branding/navLogo.png"

                            alt="Aptedge Logo"
                            placeholder="none"
                            loading="eager"
                        />
                    )}
                </a>
                <ul className="navbar-center">
                    <li style={{ display: 'block' }}>
                        <AppDropDown name="Use Cases" className={navLinkClass} link={'/'} dropDownContent={useCasesDropDownContent} />
                    </li>
                    <li style={{ display: 'block' }}>
                        <AppDropDown name="Technology" className={navLinkClass} link={'/technology/'} technology />
                    </li>

                    <li style={{ display: 'block' }}>
                        <AppDropDown name="About" className={navLinkClass} link={'/about/'} dropDownContent={aboutdropDownContent} />
                    </li>
                    <li style={{ display: 'block' }}>
                        <AppDropDown name="Resources" className={navLinkClass} link={'#'} flex={"col"} dropDownContent={resourcesdropDownContent} />
                    </li>

                </ul>
                <ul className="navbar-right">
                    <li>
                        <Link
                            to="/get-started/"
                            className={button ? `${navLinkClass} primary ${button}` : `${navLinkClass} primary`}
                            onClick={() => sendEventToGa('click', { button: 'get_started' })}
                        >
                            See it in Action
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="container mobile-navbar-wrapper">
                <Link to="/" className="navbar-logo">
                    <StaticImage
                        src="../../assets/branding/navLogo.png"

                        alt="Aptedge Logo"
                        placeholder="none"
                        loading="eager"
                    />
                </Link>
                <div className="mobile-navbar-links-wrapper">
                    {/* <Link
                        id="navbar-get-started"
                        to="/get-started/"
                        className={button ? `nav-link primary ${button} mobile` : `nav-link primary mobile`}
                        onClick={() => sendEventToGa('click', { button: 'get_started' })}
                    >
                        See it in Action
                    </Link> */}
                    <div className="mobile-menu-toggle">
                        <label id="mobile-navbar-hamburger" style={{ display: 'none' }}>
                            Open mobile menu
                        </label>
                        <input
                            aria-labelledby="mobile-navbar-hamburger"
                            type="checkbox"
                            onInput={handleMobileMenuChange}
                            ref={mobileNavRef}
                        />
                        <span />
                        <span />
                        <span />
                        <div className="mobile-nav-menu">
                            <StaticImage
                                src="../../assets/branding/navLogo.png"
                                className="navbar-logo"
                                alt="Aptedge Logo"
                                placeholder="none"
                                loading="eager"
                                style={{ marginTop: '-54px' }}
                            />
                            <div className="mobile-nav-menu-wrapper">
                                <ul>
                                    <li>
                                        <Link to="/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Home
                                        </Link>
                                    </li>
                                </ul>
                                {/* --- temporarily hide these links from ui ---- */}
                                {/* <p className="text-md semi-bold half-white">Platform</p>
                                <ul>
                                    <li>
                                        <Link to="/platform/boost/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Boost
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/platform/workflow/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Workflow
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/platform/analytics/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Analytics
                                        </Link>
                                    </li>
                                </ul> */}

                                <p className="text-xs semi-bold half-white">Use Cases</p>
                                <ul>
                                    <li>
                                        <Link to="/prevent-escalations/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Prevent Escalation
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ticket-volume-reduction/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Ticket Volume Reduction
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/improved-resolution-time/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Improved Resolution Time
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/improved-csat/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Improved CSAT
                                        </Link>
                                    </li>
                                </ul>

                                <p className="text-xs semi-bold half-white">Technology</p>
                                <ul>
                                    <li>
                                        <Link
                                            to="/technology/ai-answer-engine/"
                                            className="nav-link white"
                                            onClick={handleMobileMenuClick}
                                        >
                                            AI Answer Engine
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/technology/edge-automation/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Edge Automation
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/technology/edge-automation/#proactive-analysis"
                                            className="nav-link white"
                                            onClick={handleMobileMenuClick}
                                        >
                                            Predictive Analytics
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ai-powered/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            AI Powered
                                        </Link>
                                    </li>
                                </ul>

                                <p className="text-xs semi-bold half-white">Platform</p>
                                <ul>
                                    <li>
                                        <Link to="/integrations" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Integrations
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/technology/#security" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Security
                                        </Link>
                                    </li>
                                </ul>

                                <p className="text-xs semi-bold half-white">Company</p>
                                <ul>
                                    <li>
                                        <Link to="/about/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            About
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/careers/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Careers
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/get-started" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                                <p className="text-xs semi-bold half-white">Resources</p>
                                <ul>
                                    <li>
                                        <Link to="/blog/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Blog
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/news/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            News
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/get-started/"
                                            className="nav-link white"
                                            onClick={() => {
                                                handleMobileMenuClick();
                                                sendEventToGa('click', { button: 'get_started' });
                                            }}
                                        >
                                            See it in Action
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/case-studies/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Case Studies
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/videos/" className="nav-link white" onClick={handleMobileMenuClick}>
                                            Videos
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Link
                        to="/get-started/"
                        className={button ? `${navLinkClass} primary ${button}` : `${navLinkClass} primary`}
                        onClick={() => sendEventToGa('click', { button: 'get_started' })}
                    >
                        See it in Action
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;