import React, { useEffect, useState } from 'react'
import './quicklink-above-nav.css'
import AppButton from '../CustomComponents/app-button'
import { useLocation } from '@reach/router';
// import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

function QuickLinkAboveNav() {
    const [pageLoaded, setPageLoaded] = useState(false)
    const [IntervalID, setIntervalID] = useState()
    const location = useLocation()
    // const [sliderRef] = useKeenSlider<HTMLDivElement>({
    //     centered: true,
    //     loop: true,
    //     controls: false,
    //     spacing: 0,
    //     mode: 'free-snap',
    //     created: (slider: { next: () => void; }) => {
    //         const intervalId = setInterval(() => slider.next(), 4000)
    //         // @ts-ignore
    //         setIntervalID(intervalId)
    //     },

    // });

    // NOTE: to not show the UI layout shift on the slider 
    // This will run one time after the component mounts
    useEffect(() => {
        // callback function to call when event triggers
        const onPageLoad = () => {
            setPageLoaded(true)
            // do something else
        };

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    useEffect(() => {
        return () => {
            // @ts-ignore
            clearInterval(IntervalID)
        }
    }, [])


    return (
        <div className='quicklink-above-nav'>
            {/* <div className="keen-slider" ref={sliderRef} style={{ paddingTop: "5px", visibility: pageLoaded ? 'visible' : 'hidden' }}> */}
                {/* <div className='keen-slider__slide  quicklink-funding flex justify-center align-center' style={{ height: "100%", gap: '1rem', paddingInline: "0.5em" }}>
                    <p className='quicklink-content'>AptEdge announces $11M raise to power the future of customer care with AnswerGPT&#8482; </p>
                    <AppButton variant={'variant-white'} text={'Read More'} linkTo='/blog/aptedge-closes-seed-round-of-usd11m-on-stage-2-capital' style={{ fontSize: "14px", fontWeight: "600", padding: "0.4rem 0.5rem", borderRadius: "4px" }} />
                </div> */}
                <div className='keen-slider__slide  flex justify-center align-center' style={{ height: "100%", gap: '1rem', paddingInline: "0.5em" }}>
                    <p className='quicklink-content'>Master Case Resolution in Customer Support. Discover New Strategies Live on August 15th!</p>
                    <AppButton variant={'variant-white'} text={'Sign up Now'} linkTo='https://us02web.zoom.us/webinar/register/4517219191985/WN_PnhcRU3gQ0CoMCM3QgofvQ ' style={{ fontSize: "14px", fontWeight: "600", padding: "0.4rem 0.5rem", borderRadius: "4px" }} target='_blank'/>
                </div>
                {/* <div className='keen-slider__slide  flex justify-center align-center' style={{ height: "100%", gap: '1rem', paddingInline: "0.5em" }}>
                    <p className='quicklink-content'>NEW: AptEdge Featured in Silicon Angle</p>
                    <AppButton variant={'variant-white'} text={'Read More'} linkTo='https://siliconangle.com/2023/08/16/startup-conversation-aptedge-uses-ai-natural-language-processing-streamline-customer-care/' style={{ fontSize: "14px", fontWeight: "600", padding: "0.4rem 0.5rem", borderRadius: "4px" }} target='_blank' />
                </div> */}
                {/* <div className='keen-slider__slide  flex justify-center align-center' style={{ height: "100%", gap: '1rem', paddingInline: "0.5em" }}>
                    <p className='quicklink-content'>Acceleration Economy: AptEdge Capitalizes on Generative AI to Transform Customer Service </p>
                    <AppButton variant={'variant-white'} text={'Read More'} linkTo='https://accelerationeconomy.com/ai/aptedge-capitalizes-on-generative-ai-to-transform-customer-service/' style={{ fontSize: "14px", fontWeight: "600", padding: "0.4rem 0.5rem", borderRadius: "4px" }} target='_blank' />
                </div> */}
            {/* </div> */}
        </div>
    )
}

export default QuickLinkAboveNav