import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import "./app-drop-down.css"
import securityIcon from "../../assets/icons/security-icon.svg";
import integrationIcon from "../../assets/icons/integration-icon.svg";
import gptPowered from "../../assets/icons/artificial.png"
import analyticsIcon from "../../assets/icons/analytics-icon.svg"
import unifiedIcon from "../../assets/icons/unified-icon.svg"
import automationIcon from "../../assets/icons/edg-automation-icon.svg"



function AppDropDown({ name, className, technology, dropDownContent, link,flex }: { name: string, className: string,flex?:string , technology?: boolean , dropDownContent?: any, link?: string }) {

    //  content should be recived as prop  so that we can reuse dropdown


    const technologyDropDownContent = React.useMemo(() => [
        {
            title: "AI Answer Engine",
            description: "Instant answers for immediate tier 1 resolution, reducing escalation up to 35%",
            icon: unifiedIcon,
            link: "/technology/ai-answer-engine/",
        },
        {
            title: "Edge Automation",
            description: "Automate and deflect repetitive support issues, leading to 30% more agent productivity",
            icon: automationIcon,
            link: "/technology/edge-automation/",
        },
        {
            title: "Predictive Analytics",
            description: "Improve first contact resolutions and average handle time with accurate answers by up to 40%",
            icon: analyticsIcon,
            link: "/technology/edge-automation/#proactive-analysis",
        },
        {
            title: "AI Powered",
            description: "AptEdge is building the industry first “Answer Engine” powered by Generative AI",
            icon: gptPowered,
            link: "/ai-powered/",
        },
    ], [])

    return (
        <div className="dropdown">
            <Link to={link ? link : ""} style={{ textDecoration: 'none' }}>
                <p className={className}>{name}</p>
            </Link>
            {technology ?
                <div className="dropdown-content">
                    <div className='two-columns'>
                        {technologyDropDownContent.map(each =>
                            <Link to={each.link} style={{ textDecoration: 'none' }} >
                                <div className='flex link-container'>
                                    <div>
                                        <img
                                            src={each.icon}
                                            alt="use cases indivdual icon"
                                            loading='lazy'
                                            className='icon'
                                        />
                                    </div>
                                    <div>
                                        <p className='link-title'>{each.title}</p>
                                        <p className='link-description'>{each.description}</p>
                                    </div>
                                </div>

                            </Link>
                        )}
                    </div>
                    <div className='platform-section'>
                        <p className='subnav-title'>Platform</p>
                        <div className='flex'>

                            <Link to="/integrations" style={{ textDecoration: 'none' }} >
                                <div className='flex link-container'>
                                    <div>
                                        <img
                                            src={integrationIcon}
                                            alt="use cases indivdual icon"
                                            loading='lazy'
                                            className='icon'
                                        />
                                    </div>
                                    <div>
                                        <p className='link-title'>Integrations</p>
                                        <p className='link-description'>Improve first contact resolutions and average handle time with accurate answers by up to 40%</p>
                                    </div>
                                </div>

                            </Link>
                            <Link to="/technology/#security" style={{ textDecoration: 'none' }} >
                                <div className='flex link-container'>
                                    <div>
                                        <img
                                            src={securityIcon}
                                            alt="use cases indivdual icon"
                                            loading='lazy'
                                            className='icon'
                                        />
                                    </div>
                                    <div>
                                        <p className='link-title'>Security</p>
                                        <p className='link-description'>Improve first contact resolutions and average handle time with accurate answers by up to 40%</p>
                                    </div>
                                </div>

                            </Link>
                        </div>
                    </div>
                </div>
                :
                <div className="dropdown-content" >
                    <div className={flex==="col"  ? "two-columns-col" :'two-columns' }>
                        {dropDownContent.map((each: { link: string; icon: string | undefined; class: string; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) =>
                            <Link to={each.link} style={{ textDecoration: 'none' }} key={each.link}>
                                <div className='flex link-container'>
                                    <div>
                                        {each.icon && <img
                                            src={each.icon}
                                            alt="use cases indivdual icon"
                                            loading='lazy'
                                            className={each.class ? 'about-icon' : 'icon'}
                                        />}
                                    </div>
                                    <div>
                                        <p className='link-title'>{each.title}</p>
                                        <p    className={each.class ? 'link-description-hide' : 'link-description'}>{each.description}</p>
                                    </div>
                                </div>

                            </Link>
                        )}
                    </div>
                </div>}
        </div>
    )
}

export default AppDropDown;