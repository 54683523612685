import React, { useRef, useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import emailjs from 'emailjs-com';

import { validateEmail, sendEventToGa } from '../../utils';
import validateBusinessEmail from '../../utils/validate-business-email';
import './footer.css';
import img1 from '../../assets/home-v3/AICPA.png'
import img2 from '../../assets/home-v3/HIPAA.png'
import img3 from '../../assets/home-v3/Encryption.png'

const Footer: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = () => {
        setEmailError(false);
        if (!validateBusinessEmail(email)) {
            return setEmailError(true);
        }

        // const form = formRef.current;
        // if (form) {
        //     emailjs
        //         .sendForm('service_zvjj6xl', 'template_25flldy', form, '5ufFoFuB6aOKrRRGZ')
        //         .then(
        //             result => {
        //                 console.log(result);
        //                 // window.location.reload();
        //             },
        //             error => {
        //                 console.log(error.text);
        //             }
        //         );
        // } else {
        //     console.error('Form reference is null');
        // }

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                'form-name': 'footer-contact-form',
                'email': email
            }).toString()
        })
            .then(() => {
                setEmailSuccess(true);
                sendEventToGa('form_submit', { form: 'footer-contact-form' });
            })
            .catch(error => {
                console.error(error);
                setEmailError(true);
            });
        return true;
    };

    return (
        <footer>
            <div className="container footer-wrapper">
                <Link to="/" className="footer-logo">
                    <StaticImage
                        src="../../assets/branding/navLogo.png"
                        layout="fixed"
                        alt="Aptedge Logo"
                        width={145}
                        loading="eager"
                        placeholder="none"
                        className="logo"
                    />
                </Link>

                <div className="footer-col">
                    <p className="text-sm semi-bold">Company</p>
                    <ul>
                        <li>
                            <Link to="/about/" className="footer-link">
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to="/careers/" className="footer-link">
                                Careers
                            </Link>
                        </li>
                        <li>
                            <Link to="/get-started" className="footer-link">
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="footer-col">
                    <p className="text-sm semi-bold">Resources</p>
                    <ul>
                        <li>
                            <Link
                                to="/get-started/"
                                className="footer-link"
                                onClick={() => sendEventToGa('click', { button: 'get_started' })}
                            >
                                Get a Demo
                            </Link>
                        </li>
                        <li>
                            <Link to="/blog/" className="footer-link">
                                Blog
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-form-wrapper">
                    {emailSuccess ? (
                        <div className="form-success" style={{ height: '173px' }}>
                            <p className="text-md semi-bold">Thanks! Your email has been recorded.</p>
                        </div>
                    ) : (
                        <>
                            <p className="text-sm semi-bold">Stay updated</p>
                            <form
                                name="footer-contact-form"
                                method="post"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                noValidate
                                ref={formRef}
                                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <div className="input-wrapper">
                                    <label id="footer-subscribe-email">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        aria-labelledby="footer-subscribe-email"
                                        placeholder="Your email address"
                                        className={emailError ? 'error' : ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setEmail(e.currentTarget.value);
                                            setEmailError(false);
                                        }}
                                    />
                                    <p className={emailError ? 'input-error error' : 'input-error'}>Please enter a valid email address</p>
                                </div>
                                <input type="hidden" name="form-name" value="footer-contact-form" />
                                {/* <input type="submit" value="Subscribe" /> */}
                                <a className="footer-cta" onClick={handleSubmit} style={{ marginTop: '14px' }}>
                                    Subscribe
                                </a>
                            </form>
                        </>
                    )}
                </div>
            </div>
            <div className='footer-logos'>
                <img src={img1} />
                <img src={img2} />
                <img src={img3} />
            </div>

            <div className="container">
                <div className="footer-bottom-wrapper">
                    <p className="text-sm silver">Copyright © {new Date().getFullYear()} AptEdge – All Rights Reserved</p>
                    <div className="flex">
                        <Link to="/website-privacy-policy/" className="footer-link small">
                            Privacy Policy
                        </Link>
                        <Link to="/terms-of-service/" className="footer-link small">
                            Terms of Service
                        </Link>
                        <Link to="/technology/#security/" className="footer-link small">
                            Enterprise-Grade Security
                        </Link>
                        <Link to="https://app.vanta.com/aptedge.io/trust/z6j3lkon09m917xzfaxk6v" className="footer-link small" target='_blank'>
                            Trust Center
                        </Link>
                    </div>
                </div>
            </div>
            <noscript>
                <img src="https://ws.zoominfo.com/pixel/61aab57a8a6093001ca5b8b9" width="1" height="1" style={{ display: 'none' }} />
            </noscript>
        </footer>
    );
};

export default Footer;
